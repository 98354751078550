import React, { useEffect } from 'react'
import { particles } from "../../lib/utils";


function Title() {
    useEffect(() => {
        particles()
    }, []);
    return (
        <>
            <div className='transition-none'>
                <section className="title-hero-bg parallax-effect bg-blog">
                    <div id="particles-js"></div>
                    <div className="container">
                        <div className="page-title text-center">
                            <h1 className="text-white">
                                Blogs | Stati<span className="default-color">Sense</span>
                            </h1>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Title