import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./component/header/Header"
import Footer from "./component/footer/Footer";
import About from "./views/About";
import Home from "./views/Home";
import Services from "./views/Services";
import Blogs from "./views/Blogs";
import Contact from "./views/Contact";
import ScrollToTop from "./component/ScrollToTop";

function App() {
  return (
    <div>
      <Header />
        <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blog/*" element={<Blogs />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
