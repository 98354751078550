import React from 'react'
import { useNavigate } from "react-router-dom";
import { BlogsData } from "../../api/blogsData";


function Blogs() {
    const navigate = useNavigate();

    return (
        <>
            <section className="grey-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 section-heading">
                            <h2 className="mt-10 font-700">Our Blogs</h2>
                            <hr className="dark-bg center_line bold-line" />
                            {/* <!-- <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, facilis.</h4> --> */}
                        </div>
                    </div>
                    <div className="row mt-30 blog-style-01">

                        {
                            BlogsData.slice(0, 3).map((blogs) => {
                                return (
                                    <div
                                        className="mb-5 col-lg-4 col-md-6 col-sm-4 col-xs-12 wow fadeInRight"
                                        data-wow-delay="0.2s"
                                    >
                                        <div className="post">
                                            <div className="post-img">
                                                {" "}
                                                <img onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)}
                                                    className="img-responsive"
                                                    src={blogs.imgSrc}
                                                    alt=""
                                                />{" "}
                                            </div>
                                            <div onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)} className="post-info all-padding-40">
                                                <h3 className='breadcrumb-link pointer'>
                                                    {blogs.title.length > 55 ? `${blogs.title.substring(0, 55)} ...` : blogs.title}
                                                    {" "}
                                                </h3>
                                                <h6>{blogs.date}</h6>
                                                <hr />
                                                <p className="mt-10">
                                                    {" "}
                                                    <span>
                                                        <span className="extras-wrap">
                                                            <i className="icofont icofont-business-man"></i>
                                                            <span>{blogs.author}</span>
                                                        </span>{" "}
                                                    </span>{" "}
                                                </p>
                                                <h3 className="readmore font-14px text-black breadcrumb-link pointer" onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)}>
                                                    <span>Read More</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="d-flex justify-content-center mt-50">
                        <button onClick={() => navigate("/blog/all")} className="btn btn-stati btn-md btn-default">View Blogs</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogs