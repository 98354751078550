import React from 'react'
import { useNavigate } from "react-router-dom";


function About() {
    const navigate = useNavigate();

    return (
        <>
            <section className="pt-0 pb-0 grey-bg">
                <div className="container-fluid">
                    <div className="row row-flex flex-center">
                        <div className="bg-about col-md-6 col-sm-12 bg-flex-cover"></div>
                        <div className="col-md-6 col-sm-12">
                            <div className="col-inner spacer">
                                <div className="service-slider text-center slick">
                                    <div className="service-item">
                                        <h2 className="mt-10 font-700">About Us</h2>
                                        <hr className="dark-bg center_line bold-line" />
                                        <div className="about-statisense row mt-30">
                                            <div className="col-md-12">
                                                <p>
                                                    StatiSense is a data consulting firm with expertise
                                                    in providing data services such as Analytics,
                                                    Research, Reporting, Measurement & Evaluation, and
                                                    Training to individuals, private organizations, and
                                                    non-governmental organizations.{" "}
                                                </p>
                                                <p>
                                                    We are a trusted analytics company with a brilliant
                                                    team of analysts. We seek to inspire through
                                                    knowledge that empowers our clients to create
                                                    actionable strategies that drive positive results.
                                                </p>
                                                <p className="mt-30">
                                                    <button className="btn btn-stati btn-md btn-default" onClick={() => navigate("/contact")}>
                                                        Talk to us
                                                    </button>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About