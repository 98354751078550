import React, {useEffect} from 'react'
import { particles } from "../../lib/utils";


function Hero() {
    useEffect(() => {
        particles()
    }, []);
    return (
        <>
            <section className="home-slider light-slider dark-block">
                <div className="default-slider slick no-direction">
                    <div className="slide">
                        <div className="about-bg slide-img parallax-effect"></div>
                        <div id="particles-js"></div>
                        <div className="hero-text-wrap">
                            <div className="hero-text">
                                <div className="pt-0 container">
                                    <div className="row">
                                        <div className="pt-190 col-md-6">
                                            <div className="text-white">
                                                <h2
                                                    className="home-text text-white font-700"
                                                >
                                                    Need your data analyzed for better <span className="default-color">Insights ?</span>
                                                </h2>
                                                <h5 className="text-white">
                                                    Our data services deliver insights for{" "}
                                                    <span>Better </span> business
                                                    <span> Intelligence</span>
                                                </h5>
                                                <p>
                                                    <button className="btn btn-stati btn-lg btn-default">
                                                        Request Data
                                                    </button>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero