import React from "react";
import { useForm } from "react-hook-form";

function Newsletter() {
  const { register, handleSubmit } = useForm({ defaultValues: "" });

  const submit = (event) => {
    event.preventDefault()
  };

  return (
    <>
      <section className="bg-green-two">
        <div className="container">
          <div className="align-items-stretch row">
            <div className="pt-5 pb-5 col-md-6">
              <div className="row">
                <div className="col-sm-8 section-heading white-color">
                  <h2 className="mt-0 font-700">Sign up to our newsletter</h2>
                  <hr className="center_line default-bg bold-line" />
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-md-12">
                  <form
                    onSubmit={handleSubmit(submit)}
                    className="contact-form-style-04"
                  >
                    <div className="messages"></div>
                    <div className="row">
                      <div className="offset-md-2 col-md-8 col-sm-8">
                        <div className="form-group">
                          <label className="sr-only" htmlFor="name">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="md-input style-02"
                            placeholder="Your Name"
                            {...register("name", { required: true })}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="offset-md-2 col-md-8 col-sm-8">
                        <div className="form-group">
                          <label className="sr-only" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="md-input style-02"
                            placeholder="Your Email"
                            {...register("email", { required: true })}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="offset-md-2 col-md-8 col-sm-8">
                        <div className="d-flex justify-content-center text-left mt-20">
                          <button
                          disabled
                            type="submit"
                            name="submit"
                            className="btn btn-stati btn-md btn-default"
                          >
                            <span>
                              Subscribe{" "}
                              <i className="ion-android-arrow-forward"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="newsletterImg"
                src="/assets/images/1.jpg"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Newsletter;
