import React from 'react'
import { useNavigate } from "react-router-dom";


function DataInHand() {
    const navigate = useNavigate();

    return (
        <>
            <section className="data-in-hand">
                <div className="container">
                    <div className="row">
                        <div className="about-statisense col-md-6 parallax-content">
                        <div className="sec-head  text-center">
                                <h2 className="default-color mt-10 font-700">
                                    We are data
                                </h2>
                                <hr className="default-bg center_line bold-line" />
                            </div>
                            <p className="mt-30 text-white text-center">
                                We are a quickly evolving Data Agency based in Lagos Nigeria,
                                focused on making sense of data for a better decision. We
                                offer entire solutions and full long-term technical support in
                                these areas. We enjoy cooperating with ambitious startups and
                                developing solid data frameworks and insights.
                            </p>
                            <div className="d-flex justify-content-center mt-20">
                                <button className="btn btn-stati btn-lg btn-default" onClick={() => navigate("/contact")}>Talk to us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DataInHand