import React from 'react'
import { useContactMessagesMutation } from '../../api/statisense'
import { useForm } from "react-hook-form";


function ContactForm() {
    const { mutateAsync: contactMessages, isLoading: isContactMessagesLoading } = useContactMessagesMutation();

    const { register, handleSubmit } = useForm({ defaultValues: "" });

    const submit = async ({ name, email, comment }) => {
        try {
            const payload = {
                data: {
                    name: name,
                    email: email,
                    comment: comment
                }
            };
            await contactMessages(payload);
        } catch (e) {
            console.log({ e });
        }
    }
    return (
        <>
            <section className="graphics-bg white-bg transition-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-8 col-xs-12 xs-mb-30">
                            <h3 className="font-700">LET'S DO SOMETHING AMAZING</h3>
                            <hr className="left-line dark-bg" />
                            <p>
                                Always feel free to contact us, We are open to discussing new projects, creative ideas or opportuinities to be part of your visions.
                            </p>
                            <form
                                onSubmit={handleSubmit(submit)}
                                className="contact-form-style-03 mt-50"
                            >
                                <div className="messages"></div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="name">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="md-input"
                                        placeholder="Name"
                                        {...register('name', { required: true })}
                                    />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="md-input"
                                        placeholder="Email"
                                        {...register('email', { required: true })}
                                    />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="message">
                                        Message
                                    </label>
                                    <textarea
                                        name="message"
                                        className="md-textarea"
                                        rows="7"
                                        placeholder="Message"
                                        {...register('comment', { required: true })}
                                    ></textarea>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <button
                                    type="submit"
                                    name="submit"
                                    className="btn btn-dark btn-md remove-margin btn-square"
                                >
                                    {
                                        isContactMessagesLoading ?
                                            <>
                                                <span>
                                                    Submitting {" "}
                                                    <i className="spin fa-solid fa-spinner"></i>
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span>
                                                    Send message{" "}
                                                    <i className="ion-android-arrow-forward"></i>
                                                </span>
                                            </>
                                    }
                                </button>
                            </form>
                        </div>
                        <div className="offset-md-1 col-md-4 col-sm-5 col-xs-12 xs-mb-30">
                            <h4 className="font-700 mt-30">Contact Info</h4>
                            <hr className="left-line dark-bg" />
                            <h5 className="font-600 mt-40">
                                Address
                            </h5>
                            <p className="mt-20 font-16">
                                1038B Ologun Agbaje, Victoria Island, Lagos
                            </p>
                            <h5 className="font-600 mt-40">
                                Phone
                            </h5>
                            <p className="font-16px">Call Us: +234 901 800 1800</p>
                            <h5 className="font-600 mt-40">
                                Email
                            </h5>
                            <p className="font-16px">Email Us: support@statisense.com</p>
                            <div className="social-icons-style-12">
                                <ul className="lg-icon mb-0">
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/StatiSense" className="facebook" rel="noreferrer">
                                            <i className="icofont icofont-social-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/StatiSense/" className="twitter" rel="noreferrer">
                                            <i className="icofont icofont-social-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://instagram.com/StatiSense/" className="instagram" rel="noreferrer">
                                            <i className="icofont icofont-social-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.youtube.com/c/StatiSenseData" className="youtube" rel="noreferrer">
                                            <i className="icofont icofont-social-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactForm