import React from 'react'
import About from '../component/home/About'
import Blogs from '../component/home/Blogs'
import DataInHand from '../component/home/DataInHand'
import Faq from '../component/home/Faq'
import Hero from '../component/home/Hero'
import Newsletter from '../component/home/Newsletter'
import Services from '../component/home/Services'
import Strip from '../component/home/Strip'
import Tweets from '../component/home/Tweets'
import WhatWeDo from '../component/home/WhatWeDo'

function Home() {

    return (
        <>
            <div className="wrapper">
                <Hero />
                <About />
                <WhatWeDo />
                <Strip />
                <Services />
                <Tweets />
                <Faq />
                <DataInHand />
                <Blogs />
                <Newsletter />
            </div>
        </>
    )
}

export default Home