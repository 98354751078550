import React from 'react'
import BlogRoutes from '../component/blog/BlogRoutes'
import Title from '../component/blog/Title'

function Blogs() {
    return (
        <>
            <Title />
            <BlogRoutes />
        </>
    )
}

export default Blogs