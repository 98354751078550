import React from 'react'

function Values() {
    return (
        <>
            <section className="default-bg white-bg">
                <div className="container pt-120">
                    <div className="row">
                        <div className="peg-left">
                            <img src="/assets/images/peg.png" alt="" />
                        </div>
                        <div className="peg-right">
                            <img src="/assets/images/peg.png" alt="" />
                        </div>
                        <div className="white-bg col-sm-8 values-bg">
                            <div className="container">
                                <h4 className="font-700 pt-40">Values
                                    <br />& Princi<div className="magnifier">ples</div>
                                </h4>
                            </div>
                            <div className="pt-50 accordion">
                                <input className="accordion-input" name="accordion-actions" type="checkbox" id="accordion-item-1" />
                                <div className="accordion-item">
                                    <label for="accordion-item-1" className="accordion-header">
                                        Integrity
                                    </label>
                                    <div className="accordion-content">
                                        <div className="content">
                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, necessitatibus. */}
                                        </div>
                                    </div>
                                </div>
                                <input className="accordion-input" name="accordion-actions" type="checkbox" id="accordion-item-2" />
                                <div className="accordion-item">
                                    <label for="accordion-item-2" className="accordion-header">
                                        Commitment
                                    </label>
                                    <div className="accordion-content">
                                        <div className="content">
                                            {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, cum? */}
                                        </div>
                                    </div>
                                </div>
                                <input className="accordion-input" name="accordion-actions" type="checkbox" id="accordion-item-3" />
                                <div className="accordion-item">
                                    <label for="accordion-item-3" className="accordion-header">
                                        Confidentiality
                                    </label>
                                    <div className="accordion-content">
                                        <div className="content">
                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, est! */}
                                        </div>
                                    </div>
                                </div>
                                <input className="accordion-input" name="accordion-actions" type="checkbox" id="accordion-item-4" />
                                <div className="accordion-item">
                                    <label for="accordion-item-4" className="accordion-header">
                                        Objectivity
                                    </label>
                                    <div className="accordion-content">
                                        <div className="content">
                                            {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe, animi? */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, quas. </h4> --> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center row mt-50">

                    </div>
                </div>


            </section>
        </>
    )
}

export default Values