import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

function Faq() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                {/* <!-- <h5 className="default-color text-uppercase font-600">Best Features</h5> --> */}
                                <h2 className="mt-10 font-700">Frequently Asked Questions</h2>
                                <hr className="dark-bg center_line bold-line" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div className="col-md-6">
                            <ul className="accordion-list">
                                <Accordion>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>What does Statisense do?</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>How do I request for Infographics?</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>
                                                        How credible are the infographics provided from
                                                        Statisense
                                                    </h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>How can I subscribe to the newsletter</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                </Accordion>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="accordion-list">
                                <Accordion>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>How old is Statisense?</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>How do I follow Statisense on social media</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>
                                                        How do I request for Statisense to analyze my data
                                                    </h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                    <li>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <h3>Is Statisense a Nigerian company?</h3>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="answer">
                                                    <p>
                                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing
                                                        elit. Et, mollitia. */}
                                                    </p>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </li>
                                </Accordion>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq