import React, {useEffect} from 'react'
import { particles } from "../../lib/utils";


function Title() {
    useEffect(() => {
        particles()
    }, []);
    return (
        <>
            <section className="title-hero-bg parallax-effect bg-blog">
                <div id="particles-js"></div>
                <div className="container">
                    <div className="page-title text-center">
                        <h1 className="text-white">
                            Services | Stati<span className="default-color">Sense</span>
                        </h1>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Title