import React from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <div>
      <nav className="dark-bg navbar navbar-default navbar-fixed navbar-transparent dark bootsnav on no-full">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              {" "}
              <i className="tr-icon ion-android-menu"></i>{" "}
            </button>
          </div>
          {/* <!--== End Header Navigation ==--> */}

          {/* <!--== Collect the nav links, forms, and other content for toggling ==--> */}
          <div className="text-white collapse navbar-collapse" id="navbar-menu">
            <ul
              className="d-flex align-items-center justify-content-space-between nav navbar-nav navbar-right"
              data-in="fadeIn"
              data-out="fadeOut"
            >
              <li className="megamenu-fw header-link pointer" onClick={() => navigate("/")}>
                {" "}
                Home
              </li>
              <li className="header-link pointer" onClick={() => navigate("/about")}>
                {" "}
                About Us
              </li>
              <li className="header-link pointer" onClick={() => navigate("/services")}>
                {" "}
                Our Services
              </li>
              <li className="header-link pointer" onClick={() => navigate("/blog/all")}>
                Blogs
              </li>
              <button id="btn-stati" className="btn btn-stati btn-lg btn-default" onClick={() => navigate("/contact")}>Talk to us</button>
            </ul>
          </div>
          {/* <!--== /.navbar-collapse ==--> */}
        </div>
      </nav>
    </div>
  );
}

export default Header;
