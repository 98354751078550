import React from 'react'
import { useNavigate } from "react-router-dom";


function Footer() {
  const navigate = useNavigate();
  const date = new Date();
  let year = date.getFullYear();
  return (
    <div>
      <footer className="footer dark-block">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="widget widget-text">
                  <div className="logo logo-footer"><a href="index-2.html"> <img className="logo logo-display" src="#" alt="" /></a>
                    <h3 className="text-white">Stati<span className="default-color">Sense</span></h3>
                  </div>
                  {/* <!-- <h>Statisense</h> --> */}
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="widget widget-links">
                  <h5 className="widget-title">Quick Links</h5>
                  <ul>
                    <li className='breadcrumb-link pointer' onClick={() => navigate("/home")}>Home</li>
                    <li className='breadcrumb-link pointer' onClick={() => navigate("/about")}>About Us</li>
                    <li className='breadcrumb-link pointer' onClick={() => navigate("/infographics")}>Infographics</li>
                    <li className='breadcrumb-link pointer' onClick={() => navigate("/blog/all")}>Blog</li>
                    <li className='breadcrumb-link pointer' onClick={() => navigate("/contact")}>Contact Us</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="widget widget-links">
                  <h5 className="widget-title">Contact Us</h5>
                  <div className="widget-links">
                    <ul>
                      <li>1038B, Ologun Agbaje, <br /> Victoria Island, Lagos</li>
                      {/* <li>(03) 9414 7288</li> */}
                      <li>sayhi@gmail.com</li>
                      <li>www.statisense.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="copy-right text-left">© {year} Statisense. All
                  rights
                  reserved</div>
              </div>
              <div className="col-md-6 col-xs-12">
                <ul className="social-media">
                  <li><a target="_blank" href="https://www.facebook.com/StatiSense" rel="noreferrer"><i className="icofont icofont-social-facebook"></i></a></li>
                  <li><a target="_blank" href="https://twitter.com/StatiSense/" rel="noreferrer"><i className="icofont icofont-social-twitter"></i></a></li>
                  <li><a target="_blank" href="https://www.youtube.com/c/StatiSenseData" rel="noreferrer"><i className="icofont icofont-social-youtube"></i></a></li>
                  <li><a target="_blank" href="https://instagram.com/StatiSense/" rel="noreferrer"><i className="icofont icofont-social-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer