import { useMutation, useQuery } from "react-query";
import http from "../lib/http.js";

export const useSubscribeNewsletterMutation = () => {
    const { mutate, mutateAsync, isLoading } = useMutation(payload => {
        return http.post('https://statisense-backend.herokuapp.com/api/newsletter-emails', payload);
    });
    return { mutate, mutateAsync, isLoading };
};

export const useContactMessagesMutation = () => {
    const { mutate, mutateAsync, isLoading } = useMutation(payload => {
        return http.post('https://statisense-backend.herokuapp.com/api/contact-messages', payload);
    });
    return { mutate, mutateAsync, isLoading };
};

export const useGetCategoriesQuery = () => {
    const { data, isLoading, refetch, isFetching } = useQuery("categories", async () => {
        const res = await http.get('https://statisense-backend.herokuapp.com/api/categories', {
        });
        return res.data.data;
    });
    return { data, isLoading, refetch, isFetching };
};

export const useGetInfographicsQuery = () => {
    const { data, isLoading, refetch, isFetching } = useQuery("categories", async () => {
        const res = await http.get('https://statisense-backend.herokuapp.com/api/infographics/?populate=*', {
        });
        return res.data.data;
    });
    return { data, isLoading, refetch, isFetching };
};