import React from 'react'
import AboutUs from '../component/about/AboutUs'
import Hero from '../component/about/Hero'
import Strip from '../component/home/Strip'
import Values from '../component/about/Values'
import WhatWeDo from '../component/home/WhatWeDo'
import Faq from '../component/home/Faq'
import Newsletter from '../component/home/Newsletter'

function About() {
  return (
    <>
    <div className="wrapper">
        <Hero />
        <AboutUs />
        <WhatWeDo />
        <Strip />
        <Values />
        <Faq />
        <Newsletter />
    </div>
    </>
  )
}

export default About