import React from 'react'
import { Route, Routes } from "react-router-dom";
import BlogDetails from "./BlogDetails";
import BlogSideBar from "./BlogSideBar";
import BlogTopic from "./BlogTopic";

function BlogRoutes() {
    return (
        <>
            <section className="white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-sm-9 col-xs-12 xs-mb-50">
                            <Routes>
                                <Route exact path="/all" element={<BlogTopic />} />
                                <Route exact path="/:blogDetails" element={<BlogDetails />} />
                            </Routes>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <BlogSideBar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogRoutes