import React from 'react'

function Strip() {
    return (
        <>
            <section className="bg-pattern dark-bg pt-80 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 xs-mb-20 sm-mb-20">
                            <div
                                className="wow fadeInRight counter-type-2 relative"
                                data-wow-delay="0.1s"
                            >
                                <i className="icon-hourglass default-color font-50px"></i>
                                <h3 className="display-block mt-0 mb-0 line-height-26">
                                    <span className="counter font-700 white-color">352</span>
                                </h3>
                                <h5 className="mt-0 font-600 white-color">Happy Clients</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 xs-mb-20 sm-mb-20">
                            <div
                                className="wow fadeInRight counter-type-2 relative"
                                data-wow-delay="0.2s"
                            >
                                <i className="icon-lightbulb default-color font-50px"></i>
                                <h3 className="display-block mt-0 mb-0 line-height-26">
                                    <span className="counter font-700 white-color">884</span>
                                </h3>
                                <h5 className="mt-0 font-600 white-color">Year Experience</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 xs-mb-20 sm-mb-20">
                            <div
                                className="wow fadeInRight counter-type-2 relative"
                                data-wow-delay="0.3s"
                            >
                                <i className="icon-wine default-color font-50px"></i>
                                <h3 className="display-block mt-0 mb-0 line-height-26">
                                    <span className="counter font-700 white-color">662</span>
                                </h3>
                                <h5 className="mt-0 font-600 white-color">
                                    Project Completed
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 xs-mb-20 sm-mb-20">
                            <div
                                className="wow fadeInRight counter-type-2 relative"
                                data-wow-delay="0.4s"
                            >
                                <i className="icon-hotairballoon default-color font-50px"></i>
                                <h3 className="display-block mt-0 mb-0 line-height-26">
                                    <span className="counter font-700 white-color">444</span>
                                </h3>
                                <h5 className="mt-0 font-600 white-color">Awwwards</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Strip