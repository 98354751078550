import React from 'react'

function WhatWeDo() {
    return (
        <>
            <section className="dotted-bg white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 section-heading">
                            <h2 className="font-700 mt-10">What we do</h2>
                            <hr className="dark-bg center_line bold-line" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center row mt-30">
                        <div className="col-md-5 col-sm-3 col-xs-12">
                            <div
                                className="feature-box card1 text-center mb-50 feature-box-rounded wow fadeInUp center-feature"
                                data-wow-delay="0.1s"
                            >
                                <span className="font-40px services default-color font-700">
                                    01
                                </span>
                                <h4 className="text-white mt-5 font-700">Evidence Based Advocacy</h4>
                                <p className="font-400">
                                    We provide the needed data for evidence-based home advocacy.
                                    We also help make sense of your own data for reporting and
                                    improvment purposes for better decision making
                                </p>
                                <div className="go-corner" href="#">
                                    <div className="go-arrow">→</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-3 col-xs-12">
                            <div
                                className="feature-box card1 text-center mb-50 feature-box-rounded wow fadeInUp center-feature"
                                data-wow-delay="0.2s"
                            >
                                <span className="font-40px services default-color font-700">
                                    02
                                </span>
                                <h4 className="text-white mt-5 font-700">
                                    Data Analysis and Visualization
                                </h4>
                                <p className="font-400">
                                    We put data in centext and make it real for better
                                    understanding through developing actionable and compelling
                                    infographics. This makes complex information eye-catching,
                                    shareable and easily digestible
                                </p>
                                <div className="go-corner" href="#">
                                    <div className="go-arrow">→</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-3 col-xs-12">
                            <div
                                className="feature-box card1 text-center mb-50 feature-box-rounded wow fadeInUp center-feature"
                                data-wow-delay="0.3s"
                            >
                                <span className="font-40px services default-color font-700">
                                    03
                                </span>
                                <h4 className="text-white mt-5 font-700">Monitoring and Evaluation</h4>
                                <p className="font-400">
                                    We help in monitoring and evaluation of documents and
                                    looking into your data. We also help analyze interventions,
                                    projects, or programs for perfomance evaluation to achieve
                                    the desired result
                                </p>
                                <div className="go-corner" href="#">
                                    <div className="go-arrow">→</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-3 col-xs-12">
                            <div
                                className="feature-box card1 text-center mb-50 feature-box-rounded wow fadeInUp center-feature"
                                data-wow-delay="0.3s"
                            >
                                <span className="font-40px services default-color font-700">
                                    04
                                </span>
                                <h4 className="text-white mt-5 font-700">Professional Training</h4>
                                <p className="font-400">
                                    We train on how to collect, document, analyze, interpret,
                                    report, present, and publish data for maximum impact. We
                                    train data analysts, data scientists, and also data
                                    engineers
                                </p>
                                <div className="go-corner" href="#">
                                    <div className="go-arrow">→</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhatWeDo