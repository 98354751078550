import React from 'react'
import OurServices from '../component/services/Services'
import Title from '../component/services/Title';

function Services() {
    return (
        <>
            <div className='transition-none'>
                <Title/>
                <OurServices />
            </div>
        </>
    )
}

export default Services