import React from 'react'

function Services() {
    return (
        <>
            <section className="dotted-bg about-ar bg-white bords lficon section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                <h2 className="mt-10 font-700">Our Services</h2>
                                <hr className="dark-bg center_line bold-line" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div className="col-lg-4 valign">
                            <div className="img">
                                <div
                                    className="bg-hero bg-img bg-fixed hero-bg"
                                    data-background="./assets/images/background/hero.jpg"
                                ></div>
                                <div className="exp valign text-center">
                                    <div className="full-width">
                                        <h2
                                            className="bg-hero bg-img bg-fixed"
                                            data-background="./assets/images/background/hero.jpg"
                                        >
                                            Training
                                        </h2>
                                        <p>
                                            We train on how to collect, document, analyze,
                                            interpret, report, present, and publish data for maximum
                                            impact using different data tools.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 valign">
                            <div className="img">
                                <div
                                    className="bg-hero bg-img bg-fixed hero-bg"
                                    data-background="img/arch/hero.jpg"
                                ></div>
                                <div className="exp valign text-center">
                                    <div className="full-width">
                                        <h2
                                            className="bg-hero bg-img bg-fixed"
                                            data-background="img/arch/hero.jpg"
                                        >
                                            Survey Research
                                        </h2>
                                        <p>
                                            We conduct both quantitative and qualitative research.
                                            Our in-house research team develop and evaluate
                                            questionnaires vis-à-vis research objectives and analyze
                                            questionnaires to develop actionable reports and
                                            communiques.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 valign">
                            <div className="img">
                                <div
                                    className="bg-hero bg-img bg-fixed hero-bg"
                                    data-background="img/arch/hero.jpg"
                                ></div>
                                <div className="exp valign text-center">
                                    <div className="full-width">
                                        <h2
                                            className="bg-hero bg-img bg-fixed"
                                            data-background="img/arch/hero.jpg"
                                        >
                                            Evidence Based Advocacy
                                        </h2>
                                        <p>
                                            Providing the needed data for evidence-based home
                                            advocacy is core of our services. We also help make
                                            sense of your own data for reporting and improvement
                                            purposes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 valign">
                            <div className="img">
                                <div
                                    className="bg-hero bg-img bg-fixed hero-bg"
                                    data-background="img/arch/hero.jpg"
                                ></div>
                                <div className="exp valign text-center">
                                    <div className="full-width">
                                        <h2
                                            className="bg-hero bg-img bg-fixed"
                                            data-background="img/arch/hero.jpg"
                                        >
                                            Consultancy Services
                                        </h2>
                                        <p>
                                            We provide data consultancy services by listening to
                                            your data needs and deploying our highly trained
                                            professional team to deliver problem-solving solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 valign">
                            <div className="img">
                                <div
                                    className="bg-hero bg-img bg-fixed hero-bg"
                                    data-background="img/arch/hero.jpg"
                                ></div>
                                <div className="exp valign text-center">
                                    <div className="full-width">
                                        <h2
                                            className="bg-hero bg-img bg-fixed"
                                            data-background="img/arch/hero.jpg"
                                        >
                                            Infographics and Visualization
                                        </h2>
                                        <p>
                                            We put data in context and make it real for better
                                            understanding through developing actionable and
                                            compelling Infographics. This makes complex information
                                            eye catching, shareable and easily digestible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services