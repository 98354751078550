import React, { useState } from 'react'
import { Timeline, Tweet } from "react-twitter-widgets";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


function Tweets() {
    const [loadtweets, setLoadTweets] = useState(false);
    const [loadtweetprofile, setLoadTweetProfile] = useState(false);
    return (
        <>
            <section className="bg-pattern">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                <h2 className="default-color mt-10 font-700">
                                    Recent Tweets
                                </h2>
                                <hr className="default-bg center_line bold-line" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-30 row">
                        <div className="col-md-6 col-lg-4">
                            <Tweet
                                onLoad={() => {
                                    setLoadTweets(true);
                                }}
                                // renderError={(_err) => <p>Could not load timeline</p>}
                                tweetId="1341730492597608454"
                                options={{
                                    cards: "hidden",
                                    theme: "light",
                                }}
                            />
                            {loadtweets === false && (
                                <SkeletonTheme
                                    baseColor="#3f3e3e"
                                    highlightColor="#5d5d5d"
                                    borderRadius="1.5rem"
                                >
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Skeleton
                                                circle
                                                width="35px"
                                                height="35px"
                                                containerClassName="tweet-skeleton"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <Skeleton
                                                height="20px"
                                                width="70%"
                                                borderRadius="4px"
                                            />
                                            <Skeleton
                                                height="20px"
                                                borderRadius="4px"
                                            />
                                        </div>
                                        <div className="mt-3 col-md-12">
                                            <p>
                                                <Skeleton
                                                    height="300px"
                                                    containerClassName="tweet-skeleton"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </SkeletonTheme>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Timeline
                                onLoad={() => {
                                    setLoadTweetProfile(true);
                                }}
                                dataSource={{
                                    sourceType: "profile",
                                    screenName: "statisense",
                                }}
                                options={{
                                    height: "400",
                                    theme: "light",
                                }}
                            />
                            {loadtweetprofile === false && (
                                <SkeletonTheme
                                    baseColor="#3f3e3e"
                                    highlightColor="#5d5d5d"
                                    borderRadius="1.5rem"
                                >
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Skeleton
                                                circle
                                                width="35px"
                                                height="35px"
                                                containerClassName="tweet-skeleton"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <Skeleton
                                                height="20px"
                                                width="70%"
                                                borderRadius="4px"
                                            />
                                            <Skeleton
                                                height="20px"
                                                borderRadius="4px"
                                            />
                                        </div>
                                        <div className="mt-3 col-md-12">
                                            <p>
                                                <Skeleton
                                                    height="300px"
                                                    containerClassName="tweet-skeleton"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </SkeletonTheme>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Tweet
                                onLoad={() => {
                                    setLoadTweets(true);
                                }}
                                tweetId="1317388895865241600"
                                options={{
                                    cards: "hidden",
                                    theme: "light",
                                }}
                            />
                            {loadtweets === false && (
                                <SkeletonTheme
                                    baseColor="#3f3e3e"
                                    highlightColor="#5d5d5d"
                                    borderRadius="1.5rem"
                                >
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Skeleton
                                                circle
                                                width="35px"
                                                height="35px"
                                                containerClassName="tweet-skeleton"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <Skeleton
                                                height="20px"
                                                width="70%"
                                                borderRadius="4px"
                                            />
                                            <Skeleton
                                                height="20px"
                                                borderRadius="4px"
                                            />
                                        </div>
                                        <div className="mt-3 col-md-12">
                                            <p>
                                                <Skeleton
                                                    height="300px"
                                                    containerClassName="tweet-skeleton"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </SkeletonTheme>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tweets