import React from "react";
import { useNavigate } from "react-router-dom";
import { BlogsData } from "../../api/blogsData";



function BlogTopic() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="row blog-style-01">
        {
          BlogsData.map((blogs) => {
            return (
              <div
                className="mb-5 col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInRight"
                data-wow-delay="0.2s"
              >
                <div className="post">
                  <div className="post-img">
                    {" "}
                    <img onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)}
                      className="img-responsive"
                      src={blogs.imgSrc}
                      alt=""
                    />{" "}
                  </div>
                  <div onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)} className="post-info all-padding-40">
                    <h3 className="breadcrumb-link pointer">
                      {blogs.title.length > 55 ? `${blogs.title.substring(0, 55)} ...` : blogs.title}
                      {" "}
                    </h3>
                    <h6>{blogs.date}</h6>
                    <hr />
                    <p className="mt-10">
                      {" "}
                      <span>
                        <span className="extras-wrap">
                          <i className="icofont icofont-business-man"></i>
                          <span>{blogs.author}</span>
                        </span>{" "}
                      </span>{" "}
                    </p>
                    <h3 className="readmore font-14px text-black breadcrumb-link pointer" onClick={() => navigate(`/blog/${blogs.title.toLowerCase()}`)}>
                      <span>Read More</span>
                    </h3>
                  </div>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>


  );
}

export default BlogTopic;
