import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetCategoriesQuery } from '../../api/statisense'

function BlogSideBar() {
  const {
    data: categories,
    isLoading: isGetCategoriesLoading
  } = useGetCategoriesQuery();

  const navigate = useNavigate()

  return (
    <div>
      <div className="mb-50">
        <h5 className="aside-title text-uppercase">About <span className="default-color">Statisense</span></h5>
        <div onClick={() => navigate("/about")}>
          <img src="/assets/images/blog/about.jpg" alt="about-me" />
        </div>
        <p className="font-300 mt-30 font-14px">
          We seek to inspire through knowledge that empowers our clients to create actionable strategies that drive positive results.
        </p>
      </div>
      <div className="social-icons-style-06 mb-50">
        <h5 className="aside-title text-uppercase">Follow Us</h5>
        <ul className="xs-icon">
          <li>
            <a className="icon facebook" target="_blank" href="https://www.facebook.com/StatiSense" rel="noreferrer">
              <i className="icofont icofont-social-facebook"></i>
            </a>
          </li>
          <li>
            <a className="icon twitter" target="_blank" href="https://www.twitter.com/StatiSense" rel="noreferrer">
              <i className="icofont icofont-social-twitter"></i>
            </a>
          </li>
          <li>
            <a className="icon youtube" target="_blank" href="https://www.youtube.com/StatiSenseData" rel="noreferrer">
              <i className="icofont icofont-social-youtube"></i>
            </a>
          </li>
          <li>
            <a className="icon instagram" target="_blank" href="https://www.instagram.com/StatiSense" rel="noreferrer">
              <i className="icofont icofont-social-instagram"></i>
            </a>
          </li>
        </ul>
      </div>

      <div className="sidebar_widget widget_categories mb-50">
        <h5 className="aside-title text-uppercase">Infographics Categories</h5>
        {
          isGetCategoriesLoading && (
            <div>
              <p className="font-14px font-100 text-gray">
                <span>
                  Loading
                  <i className="ml-3 spin fa-solid fa-spinner"></i>
                </span>
              </p>
            </div>
          )
        }
        {
          categories?.slice(0, 5).map((cat) => {
            return (
              <ul key={cat.id}>
                <li className="breadcrumb-link pointer font-100" onClick={() => navigate(`/infographics/${cat.attributes.name.toLowerCase()}`)}>
                  {cat.attributes.name}
                </li>
              </ul>
            )
          })

        }
        <button onClick={() => navigate("/infographics")} className="btn btn-sm btn-dark-outline btn-square mt-10">
          Browse Infographics
        </button>
      </div>
      <div className="sidebar_widget widget_tag_cloud mb-50">
        <h5 className="aside-title text-uppercase">Tags</h5>
        <div className="post-tags">
          {" "}
          <button onClick={() => navigate("/infographics")}>Religion</button> <button onClick={() => navigate("/infographics")}>Population</button> <button onClick={() => navigate("/infographics")}>Debt</button>{" "}
          <button onClick={() => navigate("/infographics")}>Stock Market</button> <button onClick={() => navigate("/infographics")}>Terrorism</button>{" "}
          <button onClick={() => navigate("/infographics")}>Politics</button> <button onClick={() => navigate("/infographics")}>Poverty</button>{" "}
          <button onClick={() => navigate("/infographics")}>Education</button>{" "}
        </div>
      </div>

      <div className="sidebar_widget widget_recent_post mb-50">
        <h5 className="aside-title text-uppercase">Recent Posts</h5>
        <article className="post-item">
          <div className="post-thumb">
            <div onClick={() => navigate("/blog/all")}>
              <img
                width="100"
                height="90"
                src="/assets/images/blog/groaning.jpg"
                className="img-responsive"
                alt=""
              />
            </div>
          </div>
          <div className="post-body">
            <span className="font-14px">
              <p className="breadcrumb-link pointer text-black font-14px pb-0 mb-0" onClick={() => navigate("/blog/all")}>Economy</p>
            </span>
            <div className="post-time">25/05/2022 </div>
            <p onClick={() => navigate("/blog/all")} className="subtitle text-gray font-100 font-14px pb-0 mb-0">
              Nigerian economy is growing, NIgerian people are groaning
            </p>
          </div>
        </article>
        <article className="post-item">
          <div className="post-thumb">
            <div onClick={() => navigate("/blog/all")}>
              <img
                width="100"
                height="90"
                src="/assets/images/blog/mono-product.jpg"
                className="img-responsive"
                alt=""
              />
            </div>
          </div>
          <div className="post-body">
            <span className="font-14px">
              <p className="breadcrumb-link pointer text-black font-14px pb-0 mb-0" onClick={() => navigate("/blog/all")}>Economy</p>
            </span>
            <div className="post-time">25/05/2022 </div>
            <p onClick={() => navigate("/blog/all")} className="subtitle text-gray font-100 font-14px pb-0 mb-0">
              Nigeria is still largely a mono-product economy
            </p>
          </div>
        </article>
        <article className="post-item">
          <div className="post-thumb">
            <div onClick={() => navigate("/blog/all")}>
              <img
                width="100"
                height="90"
                src="/assets/images/blog/debt.png"
                className="img-responsive"
                alt=""
              />
            </div>
          </div>
          <div className="post-body">
            <span className="font-14px">
              <p className="breadcrumb-link pointer text-black font-14px pb-0 mb-0" onClick={() => navigate("/blog/all")}>National Debt</p>
            </span>
            <div className="post-time">25/3/2021 </div>
            <p onClick={() => navigate("/blog/all")} className="subtitle text-gray font-100 font-14px pb-0 mb-0">
              Nigeria is sitting on a keg of gunpowder
            </p>
          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogSideBar;
