import React, { useEffect, useRef} from 'react'
import { particles } from "../../lib/utils";
import Typed from "typed.js";

function Hero() {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [
                "Perception",
                "Insights",
                // "Interpreted data",
            ], // Strings to display
            // Speed settings, try diffrent values untill you get good results
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 100,
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, []);

    useEffect(() => {
        particles()
    }, []);

    return (
        <>
            <section className="home-slider light-slider dark-block">
                <div className="default-slider slick no-direction">
                    <div className="slide">
                        <div className="home-bg slide-img parallax-effect"></div>
                        <div id="particles-js"></div>
                        <div className="hero-text-wrap">
                            <div className="hero-text">
                                <div className="pt-0 container">
                                    <div className="row">
                                        <div className="pt-190 col-lg-5 col-md-7">
                                            <div className="text-white">
                                                <h2
                                                    className="home-text text-white font-700"
                                                >
                                                    We convert raw data to <span className="typing default-color" ref={el}></span>
                                                </h2>
                                                <h5 className="white-color">
                                                    Our data services deliver insights for{" "}
                                                    <span>better </span> business
                                                    <span> intelligence</span>
                                                </h5>
                                                <p>
                                                    <button className="btn btn-stati btn-lg btn-default">
                                                        Request Data
                                                    </button>{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="pt-100 globe-bg col-md-7">
                                            <img className="bounce" src="/assets/images/slides/16.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero