import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BlogsData } from '../../api/blogsData';

function BlogDetails() {
  const [ state, setState ] = useState(BlogsData)
  const { blogDetails } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = BlogsData.filter((blogs) => blogs.title.toString().toLowerCase() === blogDetails);
    setState(getDetails)
  }, [blogDetails])
  return (
    <>
    {
      state.map((details) =>{
        return(
          <>
          <div className="mt-3 container scp-breadcrumb">
          <ul className="breadcrumb">
            <li className='breadcrumb-link pointer' onClick={() => navigate("/home")}>
              Home
            </li>
            <li className='breadcrumb-link pointer' onClick={() => navigate("/blog/all")}>
              Blogs
            </li>
            <li className="active">{details.title}</li>
          </ul>
        </div>
        <h2 className='font-700 mt-3'>
          {details.title}
        </h2>
          {details.content}
          </>
        )
      })
    }
    </>
  )
}

export default BlogDetails