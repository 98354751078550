import React from 'react'
import ContactForm from '../component/contact/ContactForm'
import Title from '../component/contact/Title'

function Contact() {
  return (
    <>
    <Title />
    <ContactForm />
    </>
  )
}

export default Contact