export const BlogsData =[
    {
        title: "Nigerian economy is growing, Nigerian people are groaning",
        id: 1,
        imgSrc: "/assets/images/blog/groaning.jpg",
        date: "May 25, 2022",
        author: "Statisense",
        content: (
            <>
            <div className="mt-5 white-bg">
        <h5 className="font-15">
        Nigerians are not smiling even though the economy appears to be smiling. 
        According to the Q1 2022 published Gross Domestic Product (GDP), 
        which is the monetary value of all finished goods and services made within Nigeria 
        between January and March 2022, the Nigerian economy grew by 3.11% (real GDP growth) for 
        the 6 consecutive quarters since Q4 2020.
        </h5>
        <h5 className="font-15">
        Despite a real GDP growth of 3.11% and 13.25% (nominal GDP growth), 
        Nigerians are groaning as inflation on goods and services bight really hard on their purchasing power. 
        The table below gives a comparison between real GDP growth in Q1 over the last 6 quarters andF Year-on-Year 
        (YoY) overall and food inflation rates (March – end of quarter) for the same period.
        </h5>
        <div>
          <img src="/assets/images/blog/inflation-table.png" alt="" />
          <img className="mt-5" src="/assets/images/blog/inflation-chart.png" alt="" />
        </div>
        <h5 className="font-15">
        According to the Consumption Expenditure Pattern in Nigeria report (2019), 57% percentage of Nigerians’ expenditures go to food consumption. Unfortunately, food inflation in Nigeria is on a record high. So even though the GDP report shows a consistent growth of the economy, 
        Nigerians are having a different experience when they go to the market to meet up their consumption need.
        </h5>
        <h5 className="font-15">
        Nigeria being majorly a consumption economy, it would require government interventions to influence 
        the direction of the country’s inflation rates. Even though Agriculture is the largest sector of the 
        economy, insecurity has hugely affected farmers production capacity as they could not spend much time 
        on their farms to maximize their farming productivity. And if there is anything to change this 
        current trend of rising inflation rate, insecurity is a good place to intervene.
        </h5>
        <h5 className="font-15">
        Hopefully, the opening of the remaining land border may strengthen trade between 
        Nigerian businesses and her neighbouring countries and indeed impact on the inflation 
        rate so that as the economy grows, the people instead of groaning would proper.
        </h5>
      </div>
            </>
        )
    },
    {
        title: "Nigeria is still largely a mono-product economy",
        id: 2,
        imgSrc: "/assets/images/blog/mono-product.jpg",
         date: "May 25, 2022",
        author: "Statisense",
        content: (
            <>
            <div className="mt-5 white-bg">
        <h5 className="font-15">
        Nigeria is one of the largest oil producing economies in the world and has for many years depended 
        on its sales of crude oil to fund its government expenditures. From the early 1980s to mid-2000s, 
        oil contributed over 27% on the average to the Nigerian economy. This was the beginning of the 
        clamor for <span className="font-weight-bold">“Economic Diversification”</span> by many public and economic analysts.
        </h5>
        <h5 className="font-15">
        Times however have changed. Oil is not only drying up, but the world is also looking elsewhere for 
        sustainable energy to drive their economies. This further strengthen the call earlier made by 
        Nigerians that economic diversification is inevitable. The 2021 Gross Domestic Product report shows 
        that oil contributed a paltry 7.24% to the Nigerian economy and that made some claim that “finally, 
        Nigerian economy has been diversified”. Unfortunately, this is not the case and we will clarify 
        this with two data sources: Foreign Trade Statistics & Federal Inland Revenue Service (FIRS) 
        tax collection reports, of the last 5 years.
        </h5>
        <h5 className="font-15">
        The Foreign Trade Statistics shows Nigeria’s earnings from crude oil while the FIRS collects shows 
        tax collected across the federation. If the Nigeria’s revenue sharing formular is broadly applied 
        (without consideration to specific considerations), the Federal Govt receives 52.68% of crude oil 
        exports and 15% of collected taxes. Based on this, the table below is derived for the last 5 years.
        </h5>
        <div>
          <img src="/assets/images/blog/mono-product-table.png" alt="" />
        </div>
        <h5 className="font-15">
        These figures while grossly exaggerated, show that the Nigerian Federal Govt makes more money from 
        oil than from non-oil. So, while oil contributed to the economy just 7.24% in 2021, the share of 
        oil in terms of revenue was over 90%. This is where it shows that the economy is gross mono-product 
        economy. Economic diversification is not only by production but by the production that brings in 
        the maximum revenue. Clearly, oil may be paltry in production, it contributes the most in terms 
        of revenue.
        </h5>
      </div>
            </>
        )
    },
    {
        title: "Nigeria Debt: Nigeria is sitting on a keg of gunpowder",
        id: 3,
        imgSrc: "/assets/images/blog/debt.png",
        date: "March 25, 2021",
        author: "Statisense",
        content: (
            <>
             <div className="mt-5 white-bg">
        <h5 className="font-15">
          Nigeria’s total public debt stock rose from N8.32 trillion in Q3 2013
          to N28.63 trillion as of Q1 2020. Just like most developing countries
          of the world, Nigeria relies substantially on external funding in
          financing its infrastructural projects like roads,
          electricity-generation plants, rails, health, housing, education etc.
          However, a 244% increase in debt figures in just 7 years is huge and
          can’t be underestimated. This made international organizations like
          the World Bank and the International Monetary Fund (IMF) issue an
          advisory on the consequences of high debt on Nigeria’s economy.
        </h5>
        <h5 className="font-15">
          In an analysis carried out by StatiSense, Nigeria external debt
          profile as at March 2015 stood at $9.46billion and by March 2016, an
          additional $1.73billion has been borrowed within a year which
          increased the debt burden to $11.19 billion, which is 18% in one year.
          In the same vein, the debt burden stood at $13.81billion,
          $22.61billion, $25.61billion and $27.67billion as at March 2017, 2018,
          2019 and 2020 respectively. This trend shows Nigeria’s external debt
          increased by approximately 193% in five years.
        </h5>
        <h5 className="font-15">
          SBM Intelligence, a geopolitical research firm, reported in 2019, that
          the World Bank and the Exim Bank of China are Nigeria’s largest
          creditors with a combined portfolio of $11.46 billion. The World Bank
          Group alone has a portfolio of $8.9 billion in Nigeria, which is 34.75
          per cent of the country’s $25.61 billion external indebtedness as of
          31st, March 2019. The report further revealed that Nigeria is also
          indebted to the African Development Bank Group $1.25billion (4.88%t)
          and the African Development Fund to the tune of $834.18m (3.26%).
        </h5>
        <div>
          <img src="/assets/images/blog/debt.png" alt="" />
        </div>
        <h5 className="font-15">
          Furthermore, StatiSense reported that the domestic debt profile
          starting from March 2015 stood at N8.51 trillion as at March of 2016,
          it increased to N9.97 trillion. In the same vein, the debt burden for
          the following years stood at N11.97 trillion, N12.58 trillion, N13.11
          trillion and N14.53 trillion in March 2017, 2018, 2019 and 2020
          respectively. Just as the external debt, these trends show that
          Nigeria’s domestic debt increased by approximately 71% in five years.
        </h5>
        <h5 className="font-15">
          The report from both StatiSense and SBM, attest to the severity of
          Nigeria’s external debt overhang which has continued to hamper
          economic growth and worsen its balance-of-payments (BOP) position.
          Nigerian states are not left out of this high debt accumulation. The
          state’s debt stock has risen sharply in the last five years.
          Reflecting the deterioration of the states’ fiscal situation. The
          states have contributed N4.1trillion to Nigeria’s total debt stocks
          with states like Lagos, Rivers and Delta contributing 10.8%, 6.5%, and
          5.6% respectively to the total state’s debt according to the latest
          figures released by the Debt Management Office ( DMO).
        </h5>
        <h5 className="font-15">
          The Federal Government through the DMO has said debt service provision
          in the 2020 budget includes capital repayment, interest obligations
          and other charges. According to the 2020 budget document, debt service
          is expected to take more than 27% of the adjusted spending plan. In
          light of the recent debt-servicing difficulties that Nigeria has
          experienced, there is an apparent need for stakeholder and
          policymakers to develop an operational framework to help achieve a
          good debt management policy. When such a framework is in place, it
          would then be possible to estimate and forecast the level of debts and
          debt-servicing needs. This will help our system against likely debt
          problems for which appropriate policy action can be taken.
        </h5>
        <h5 className="font-15">
          Finally, if the acquisition of additional foreign debt increases the
          debt servicing burden than it increases the country’s capacity to bear
          the burden, such an acquisition becomes undesirable and the situation
          must be reversed through export expansion. If the export is not
          expanded, more borrowing will be necessitated for servicing debt and
          external debt will pile up above the country’s capacity.
        </h5>
      </div>
            </>
        )
    },
    {
        title: "Unemployment: Nigeria added 7.999 Ph.D holders to the labor market in 21 months",
        id: 4,
        imgSrc: "/assets/images/blog/unemployed.png",
        date: "March 25, 2021",
        author: "Statisense",
        content: (
            <>
            <div className="mt-5 white-bg">
        <h5 className="font-15">
        The National Bureau of Statistics (NBS) just released the Unemployment and Underemployment Report 
        for Q2-2020, and once again the data showed alarming figures for the unemployment rate in Nigeria, 
        reporting a national unemployment rate of 27% and Underemployment Rate of 28.6%. That’s enough to 
        cause stomach upset, but that’s not the “KOKO” of this article. The report contained some stat that 
        caught my attention. 
        </h5>
        <h5 className="font-15">
        Let’s start with the large population of Nigerians with PhD. In the last quarter of 2017, NBS 
        reported that there were 70,739 PhD holders and 72,146 in the third quarter of 2018. In the most 
        recent data for the second quarter of 2020, the number of PhD holders has increased to 76,526. 
        Also disturbing is the high number of unemployed PhD holders. According to StatiSense, the NBS 
        report shows that in 2018, there were 9,832 unemployed PhD holders in the third quarter of 2018, 
        a figure that increased from 7,817 reported for the last quarter of 2017. And in the second quarter 
        of 2020, it has increased to 17,831. This clearly means that 7,999 PhD holders have been added to 
        the unemployment market in 21 months – 381 PhD holders per month.
        </h5>
        <h5 className="font-15">
        Apart from those with PhDs, the report also reviewed that about 120,000 Nigerians with Master’s 
        Degree are unemployed and over 2.8million with Bachelor’s Degree are also unemployed. Moreover, 
        the report shows that those with Diploma (over 2million), O-Level (over 8million), Vocational 
        certification (about 50,000), and Junior Secondary school (over 1.3million) are unemployed. 
        When you consider that the national unemployment rate is at 27% and the increasing number of those 
        with qualifications and still unemployed,  you realize that an educated Nigerian is more likely to 
        be unemployed than the average Nigerian. Meaning, the likelihood of been employed having attended a 
        university is low. 
        </h5>
      </div>
            </>
        )
    }
]